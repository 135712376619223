@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

/***********NAVBAR***********/
.name-title {
  font-family: "Twinkle Star", cursive;
  font-weight: bolder;
  font-size: 30px;
  padding-bottom: 0px;
}
.logo {
  width: 80px;
  height: 60px;
  margin-left: 40px;
}
@media screen and (max-width: 810px) {
  .logo {
    width: 70px;
    height: 55px;
    margin-left: 5px;
  }
}
.tg {
  position: fixed !important;
  width: 100%;

  top: 0;
  z-index: 1;
  background-color: white;
  box-shadow: 3rem solid black;
}
.nav-link {
  font-weight: 700 !important;
  font-size: 16px !important;
  cursor: pointer;
  color: rgb(230, 0, 39) !important;
  letter-spacing: 0.6px;
}
.navClass {
  max-height: 100px;
}

.nav-link:hover {
  transform: scale(1.03);
  transition: ease-in;
  text-shadow: rgb(16, 13, 34);
  border-bottom: 1px solid rgb(230, 0, 39);
  width: fit-content;
}
.navbar-nav > li > a {
  text-transform: uppercase;
  padding-right: 10px;
}

.container-fluid {
  color: var(--dark-orange);
}

/************section*******************/

.section-head {
  margin-top: 3rem;
  height: 80vh;
  background-image: linear-gradient(
    to bottom right,
    white,
    white,
    rgb(230, 0, 39)
  );
  display: flex;
  align-items: center;
}
.holls {
  font-weight: 500;
  font-size: 50px;
  margin-left: -3rem;
}
.holls2 {
  font-weight: 500;
  font-size: 50px;
  color: white;
}
.holls-p {
  color: rgb(230, 0, 39);
  margin-top: -9px;
  font-size: 17px;
  letter-spacing: 4px;
  margin-left: -3rem;
}
.holls-p2 {
  color: rgb(230, 0, 39);
  margin-top: -9px;
  font-size: 17px;
  letter-spacing: 4px;
}
.section-container1 {
  flex: 0.9;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.section-container2 {
  flex: 1.1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section-li {
  margin: 1rem 0;
  display: flex;
  font-weight: 700;
  list-style-type: none;
}
.section-rounded {
  margin: 10px 1rem 0 0;
  height: 10px;
  width: 10px;
  border-radius: 40%;
  background-color: rgb(230, 0, 39);
  display: flex;
}
.profile-picture {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 10px 10px white;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  margin: 2rem -1rem 0 0;
  overflow: hidden;
}
.profile-picture3 {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 10px 0px 10px 10px white;
  border-radius: 50%;
  height: 180px;
  width: 180px;
  margin: -5rem 0px 0 -5rem;
}
.profile-picture1 {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 10px 0px 0px 10px white;
  border-radius: 50%;
  height: 260px;
  width: 260px;
  margin: 0 0px 0 -1rem;
}
.profile-picture-background {
  height: 94%;
  width: 94%;
  background-size: cover;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: top;
}
@media screen and (max-width: 810px) {
  .section-head {
    display: flex;
    height: 100vh;
    flex-direction: column;
    margin-top: 11rem;
    /* margin-top:13rem; */
  }
  .section-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 3rem;
  }

  .profile-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 10px 0px 0px 10px white;
    border-radius: 50%;
    height: 140px;
    width: 140px;
    margin: 2rem -1rem 0 -1rem;
    overflow: hidden;
  }
  .profile-picture3 {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 0px 0px 5px white;
    border-radius: 50%;
    height: 140px;
    width: 140px;
    margin: -5rem 0px 0 -5rem;
  }
  .profile-picture1 {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 10px 1px 0px 10px white;
    border-radius: 50%;
    height: 200px;
    width: 210px;
    margin: 0 0px 0 -1rem;
  }
  .ppg1 {
    width: 140px !important;
    height: 140px !important;
    margin-left: 30px;
  }
  .ppg2 {
    width: 200px !important;
    height: 200px !important;
    margin-left: 30px;
  }
  .ppg3 {
    width: 140px !important;
    height: 140px !important;
    margin-left: 30px;
  }
  li {
    font-size: 18px !important;
    width: 90%;
  }
}

/* About US */
.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}
.about-title {
  font-size: 35px;
  font-weight: 700;
  color: rgb(230, 0, 39);
}
.heading-line::before {
  content: "";
  width: 15rem;
  height: 0.2rem;
  display: block;
  margin: 0 auto;
  background: linear-gradient(to right, rgb(252, 226, 226), rgb(230, 0, 39));
}
.heading-line::after {
  content: "";
  width: 4rem;
  padding-top: 0.5rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  height: 0.2rem;
  display: block;
  background: linear-gradient(to right, rgb(230, 0, 39), rgb(252, 226, 226));
}

.about-picture-background {
  border-radius: 100px 0 100px 0;
  width: 100%;
  height: 400px;
}

.abt {
  line-height: 1.9rem;
}

.down-image {
  height: 300px !important;
}

@media screen and (max-width: 810px) {
  .about-picture-background {
    border-radius: 100px 0 100px 0;
    height: 250px;
    margin-top: 2rem;
  }

  .about-title {
    font-size: 25px;
  }
}

/* Service */
.service {
  display: flex;
  background-image: linear-gradient(
    to bottom right,
    white,
    rgb(252, 226, 226),
    rgb(230, 0, 39)
  );
  padding: 4rem 0;
}
.sev-tit {
  font-size: 18px;
}
.service-container1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 0.9;
}
.service-container2 {
  display: flex;
  flex-wrap: wrap;
  flex: 1.1;
  justify-content: center;
  align-items: center;
}
.service-title {
  font-size: 35px;
  font-weight: 700;
  color: rgb(230, 0, 39);
  margin-left: 4rem;
}
.service-img {
  width: 220px;
  height: 220px;
  border-radius: 10px;
  margin: 1rem 0;
  margin-right: 2.5rem;
}
@media screen and (max-width: 810px) {
  .service {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 3rem;
  }
  .service-title {
    margin-top: 5rem;
  }
  .service-img {
    width: 280px;
    height: 250px;
    margin: 1.2rem;
  }
}

/* LatestProject */
.lp {
  font-size: 35px;
  font-weight: 500;
  color: rgb(230, 0, 39);
  display: flex;
  justify-content: center;
}
.lp-p {
  font-size: 20px;
  font-weight: 500;
  margin-top: 2rem;
}
.latest-project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin: 5rem 0;
}
.lp-img {
  height: 400px !important;
}
.lp-last {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.autoplay {
  overflow: hidden;
}

.list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 9rem;
  justify-content: center;
}
.lm {
  width: 60vh;
  font-weight: 400 !important;
}
.video{
  display: flex;
 align-items: center;
   margin: 3rem 0;
  justify-content: center;
}
.vids{
  width: 600px;
  height: 300px;
  margin:0 2rem ;
}
@media screen and (max-width: 810px) {
  .lp {
    font-size: 30px;
    font-weight: 500;
    color: rgb(230, 0, 39);
    display: flex;
    justify-content: center;
    margin: auto;
    width: 60%;
  }
  .lm {
    width: 90%;
    font-weight: 400 !important;
  }
  .video{
    flex-direction: column;
  }
  .vids{
    width: 350px;
    height: 200px;
    margin:1rem 2rem ;
  }
  .list {
    padding-left: 5px;
  }
  .lp-img {
    height: 150px !important;
  }

  .lp {
    font-size: 28px;
    text-align: center;
  }
  .client {
    height: 300% !important;
    padding-top: 3rem;
  }
}

/* Client */
.client {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
    to bottom right,
    white,
    rgb(252, 226, 226),
    rgb(230, 0, 39)
  );
  margin: 3rem 0;
  align-items: center;
  justify-content: center;
}

/* Team */
.team-container {
  background-image: linear-gradient(
    to bottom right,
    white,
    rgb(252, 226, 226),
    rgb(230, 0, 39)
  );
  color: black;
  border-radius: 5px;
}
.team-container1 {
  background-image: linear-gradient(
    to bottom right,
    white,
    rgb(252, 226, 226),
    rgb(230, 0, 39)
  );
  color: black;
  border-radius: 5px;
  font-size: 18px;
}
.in {
  font-size: 45px;
  font-weight: 500;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.int {
  font-size: 70px;
  font-weight: 500;
  font-family: Georgia, "Times New Roman", Times, serif;
}
@media screen and (max-width: 810px) {
  .team-container {
    font-size: 14px;
    min-height: 60%;

    margin: auto;
    margin-top: 2rem;
    text-align: center;
  }
  .team-container1 {
    font-size: 14px;
    min-height: 60%;
    width: 60%;
    margin: auto;
    text-align: center;
  }
  .in {
    font-size: 30px;
  }
  .int {
    font-size: 43px;
  }
  .t-w {
    padding-bottom: 4rem;
  }
}

/* info */
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  background-image: linear-gradient(
    to bottom right,
    white,
    rgb(252, 226, 226),
    rgb(230, 0, 39)
  );
  justify-content: center;
}
form {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
}
.input {
  width: 65vh;
  padding: 0 10px;
  height: 40px;
  margin-bottom: 1rem;
  border: none;
  outline: none;
  border-radius: 5px;
}
.input:focus {
  border: 2px solid rgb(230, 0, 39);
}
.message {
  width: 65vh;
  height: 180px;
  padding-top: -9rem;
  padding: 0 10px;
  margin-bottom: 1rem;
  border: none;
  outline: none;
  border-radius: 5px;
}
.button {
  width: 15vw;
  height: 40px;
  color: white;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: rgb(230, 0, 39);
}
.button1 {
  width: 15vh;
  height: 40px;
  color: white;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: rgb(230, 0, 39);
}

.h2 {
  font-size: 35px;
  font-weight: 500;
  color: rgb(230, 0, 39);
}
@media screen and (max-width: 810px) {
  .input {
    width: 300px;
    padding: 0 10px;
    height: 40px;
    margin-bottom: 1rem;
    border: none;
    outline: none;
    border-radius: 5px;
  }
  .contact {
    height: 100%;
    padding-bottom: 4rem;
  }
  .message {
    width: 300px;
    height: 180px;
    padding-top: -9rem;
    padding: 0 10px;
    margin-bottom: 1rem;
    border: none;
    outline: none;
    border-radius: 5px;
  }
}

/* Footer */

.footer {
  height: 40vh;
  background-color: black;
  color: white;
  display: flex;
  width: 100%;
}

.one {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.two {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.three {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.social-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.float {
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;
  margin: 2rem 3rem;
}
.social-icon1 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.a-t {
  text-decoration: none;
  color: white;
}
.i-c {
  display: flex;
  margin-top: 1.5rem;
}
.tree {
  text-decoration: none;
  color: white;
  padding-left: 10px;
  padding-bottom: 2rem;
}
.pone {
  margin-right: 10px;
}
.f {
  width: 45px !important;
  height: 45px !important;
}
.last {
  color: white;
  display: flex;
  justify-content: center;
  background-color: black;
}
@media screen and (max-width: 810px) {
  .footer {
    height: 100%;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
  }
  .two {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .float {
    margin: 1rem 1rem;
  }
  .social-icon1 {
    width: 60px;
    height: 60px;
  }
  .last {
    color: white;
    display: flex;
    justify-content: center;
    background-color: black;
    font-size: 12px;
    margin: -2px 0;
  }
  .button, .button1{
    width: 50vw;
  }
}
